import createIcon from 'react-share/lib/hocs/createIcon'

const PhoneIcon = createIcon({
  color: '#77b3d4',
  networkName: 'phone',
  path:
    'M 21.587891 16.003906 C 20.626028 15.991675 19.568912 16.35865 18.943359 16.984375 L 16.667969 19.263672 C 16.261359 19.668132 16.001953 20.823895 16.001953 20.828125 C 15.922353 28.054275 18.755698 35.017356 23.867188 40.128906 C 28.966127 45.227846 35.902202 48.056854 43.107422 47.996094 C 43.145222 47.996094 44.335648 47.740407 44.742188 47.335938 L 47.017578 45.060547 C 47.851648 44.226477 48.228479 42.627099 47.855469 41.505859 L 47.697266 41.025391 C 47.320036 39.904151 46.083126 38.735824 44.947266 38.427734 L 40.742188 37.279297 C 39.602097 36.969127 37.976728 37.38468 37.142578 38.21875 L 35.621094 39.740234 C 30.091464 38.245984 25.755842 33.911372 24.263672 28.382812 L 25.785156 26.861328 C 26.619296 26.027188 27.036732 24.401809 26.726562 23.261719 L 25.580078 19.056641 C 25.269908 17.918631 24.097616 16.681674 22.978516 16.308594 L 22.498047 16.146484 C 22.217734 16.053214 21.908512 16.007983 21.587891 16.003906 z '
});

export default PhoneIcon

